import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../styles/contact.scss';

const ContactPage = () => (
    <Layout>
        <SEO title="contact" />
        <div className="headerText">
            contact
        </div>
        <div className="contact-content">
            <p>
                Thank you for reaching out.
            </p>
            <p>
                I'll be getting back to you as soon as I can. In the meantime, click around and check out 
                the rest of the site.
            </p>
        </div>
    </Layout>
);

export default ContactPage;